// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-aboutus-page-js": () => import("/opt/build/repo/src/templates/aboutus-page.js" /* webpackChunkName: "component---src-templates-aboutus-page-js" */),
  "component---src-templates-vacancies-overview-js": () => import("/opt/build/repo/src/templates/vacancies-overview.js" /* webpackChunkName: "component---src-templates-vacancies-overview-js" */),
  "component---src-templates-cases-overview-js": () => import("/opt/build/repo/src/templates/cases-overview.js" /* webpackChunkName: "component---src-templates-cases-overview-js" */),
  "component---src-templates-expertise-overview-js": () => import("/opt/build/repo/src/templates/expertise-overview.js" /* webpackChunkName: "component---src-templates-expertise-overview-js" */),
  "component---src-templates-index-page-js": () => import("/opt/build/repo/src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-news-overview-js": () => import("/opt/build/repo/src/templates/news-overview.js" /* webpackChunkName: "component---src-templates-news-overview-js" */),
  "component---src-templates-vacancy-page-js": () => import("/opt/build/repo/src/templates/vacancy-page.js" /* webpackChunkName: "component---src-templates-vacancy-page-js" */),
  "component---src-templates-case-page-js": () => import("/opt/build/repo/src/templates/case-page.js" /* webpackChunkName: "component---src-templates-case-page-js" */),
  "component---src-templates-expertise-page-js": () => import("/opt/build/repo/src/templates/expertise-page.js" /* webpackChunkName: "component---src-templates-expertise-page-js" */),
  "component---src-templates-news-page-js": () => import("/opt/build/repo/src/templates/news-page.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-plain-page-js": () => import("/opt/build/repo/src/templates/plain-page.js" /* webpackChunkName: "component---src-templates-plain-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

