import React from 'react';
import { Auth0Provider } from './Auth';
import AuthenticationLayout from './authenticationLayout';

export default ({ element }) => {
    const isBrowser = typeof window !== "undefined";

    if (process.env.GATSBY_AUTH_ENABLED === 'true') {
        return (
            <Auth0Provider
                domain={process.env.GATSBY_AUTH_DOMAIN}
                client_id={process.env.GATSBY_AUTH_CLIENTID}
                redirect_uri={isBrowser ? window.location.origin : ''}
            >
                <AuthenticationLayout>{element}</AuthenticationLayout>
            </Auth0Provider>
        );
    }

    return element;
};
